/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 3
*/

import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Conexión con el Context API
import { GlobalContext } from "../context";

// Páginas
import Login from '../pages/login';
import LoginValidate from '../pages/login-validate';
import Restore from '../pages/restore';
import RestoreChange from '../pages/restore-change';
import Dashboard from '../pages/dashboard';
import Users from '../pages/users';
import UsersAdd from '../pages/users-add';
import UsersEdit from '../pages/users-edit';
import ClientsAdd from '../pages/clients-add';
import ClientsEdit from '../pages/clients-edit';
import ClientsDetails from '../pages/clients-details';
import ClientsArchive from '../pages/clients-archive';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        { ...rest }
        render = {
            props => {
                const user = window.localStorage.getItem("AlturaCRM");
                if (user) {
                    return <Component { ...props } />;
                } else {
                    return (
                        <Redirect to = { { pathname: "/", state: { from: props.location } } } />
                    );
                }
            }
        }
    />
);

class Routes extends Component {

    render() {
        return(
            <GlobalContext>
                <BrowserRouter>
                    <div className="flex main">
                        <div className="column full">
                            <Switch>
                                <Route path = "/" exact component = { Login } />
                                <Route path = "/login" exact component = { Login } />
                                <PrivateRoute path = "/validar" exact component = { LoginValidate } />
                                <Route path = "/restaurar" exact component = { Restore } />
                                <Route path = "/restaurar/:token" exact component = { RestoreChange } />
                                <PrivateRoute path = "/clientes" exact component = { Dashboard } />
                                <PrivateRoute path = "/clientes/nuevo" exact component = { ClientsAdd } />
                                <PrivateRoute path = "/clientes/editar" exact component = { ClientsEdit } />
                                <PrivateRoute path = "/clientes/detalles" exact component = { ClientsDetails } />
                                <PrivateRoute path = "/clientes/archivados" exact component = { ClientsArchive } />
                                <PrivateRoute path = "/usuarios" exact component = { Users } />
                                <PrivateRoute path = "/usuarios/nuevo" exact component = { UsersAdd } />
                                <PrivateRoute path = "/usuarios/editar" exact component = { UsersEdit } />
                            </Switch>
                        </div>
                    </div>
                </BrowserRouter>
            </GlobalContext>
        );
    }

}

export default Routes;