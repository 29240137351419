/**
 *  users-edit.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página para editar usuario
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Popover from '../components/popover';
import WOW from 'wowjs';

// HTTP Client
import Request from '../config/http';
const request = new Request();

class UsersEdit extends Component {

    state = { 
        user: { idUsuario: 0, nombre: '', correo: '', admin: 0}, 
        idUsuario: 0, correo: '', nombre: '', telefono: '', fecha: new Date(), empresa: '', estatus: '1', 
        admin: false, loading: false, showPopover: false, type: 'success', message: '' 
    }

    render() {
        return(
            <div className="dashboard">
                <Helmet>
                    <title>Editar usuarios - Altura CRM</title>
                </Helmet>
                <Header breadcrumb = "Tablero > Usuarios > Editar" type = { this.state.user.admin } />
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-32"></div>
                        <form className="form-users column wow animated fadeIn" onSubmit = { this.handleSubmit.bind(this) }>
                            <div className="title">
                                <h4 className="color-darkgray">
                                    EDITAR USUARIO
                                </h4>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="row-responsive full">
                                <div className="column full mr">
                                    <p className="weight-medium">
                                        Correo electrónico *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-at"></i>
                                        <input type="email" name="email" required minLength = "8" maxLength = "64" className="input full"
                                        value = { this.state.correo } disabled = { true } />
                                    </div>
                                </div>
                                <div className="column full ml">
                                    <p className="weight-medium">
                                        Nombre completo *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-font"></i>
                                        <input type="text" name="fullName" required minLength = "8" maxLength = "48" className="input full"
                                        value = { this.state.nombre } onChange = { (event) => { this.setState({ nombre: event.target.value }) } } />
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="row-responsive full">
                                <div className="column full mr">
                                    <p className="weight-medium">
                                        Teléfono *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-phone"></i>
                                        <input type="text" name="phone" required minLength = "8" maxLength = "24" className="input full"
                                        value = { this.state.telefono } onChange = { (event) => { this.setState({ telefono: event.target.value }) } } />
                                    </div>
                                </div>
                                <div className="column full ml">
                                    <p className="weight-medium">
                                        Empresa *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-building"></i>
                                        <input type="text" name="empresa" required minLength = "8" maxLength = "24" className="input full"
                                        value = { this.state.empresa } onChange = { (event) => this.setState({ empresa: event.target.value }) } />
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="row-responsive full">
                                <div className="column full mr">
                                    <p className="weight-medium">
                                        Estado:
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-shield-alt"></i>
                                        <select name="estatus" className="input input-select full" value = { this.state.estatus }
                                        onChange = { (event) => { this.setState({ estatus: event.target.value }) } } >
                                            <option value="1">Activo</option>
                                            <option value="0">Inactivo</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="column justify-center full ml">
                                    <div className="white-space-16"></div>
                                    <label className="checkbox-container ml">
                                        ¿Es administrador?
                                        <input type="checkbox" id="admin" name="admin" defaultChecked = { this.state.admin } 
                                        onChange = { (event) => this.setState({ admin: event.target.checked }) } />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            
                            <div className="white-space-32"></div>
                            <div className="btn-container row">
                                <button type="submit" className="btn btn-primary btn-radius color-white">
                                    {
                                        this.state.loading ?
                                        <span className="font-mini color-white">
                                            <i className="fas fa-spinner fa-spin"></i> &nbsp; GUARDANDO USUARIO
                                        </span>
                                        :
                                        <span className="font-mini color-white">
                                            <i className="fas fa-check"></i> &nbsp; GUARDAR USUARIO
                                        </span>
                                    }
                                </button>
                                <button type = "button" className="btn btn-danger btn-radius color-white" onClick = { this.handleExit.bind(this) }>
                                    <i className="fas fa-times"></i>
                                    &nbsp; CANCELAR
                                </button>
                            </div>
                        </form>
                        <div className="white-space-32"></div>
                        { this.state.showPopover && <Popover type = { this.state.type } message = { this.state.message } /> }
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(){
        if (this.props.location.state) {
            if (this.props.location.state.admin === 1) {
                this.loadUser();
            } else {
                this.props.history.push('/usuarios');    
            }
        } else {
            this.props.history.push('/usuarios');
        }
        new WOW.WOW({ live: false }).init();
    }

    /**
     * @function: loadUser
     * @description: Función para recuperar la información del usuario activo
    */
   async loadUser() {
        this.setState({ loadingUsers: true });
        const user = await this.props.context.loadUser();
        if (user){ 
            this.setState({ user: user.user });
            if (user.user.admin === 1) {
                this.getUser(this.props.location.state.idUsuario);
            } else {
                this.props.history.push('/clientes');
            }
        }
    }

    /**
     * @function: getUser
     * @param: idUsuario: int
     * @description: Función para obtener los datos del usuario
    */
    async getUser(idUsuario) {
        this.setState({ loading: true });
        const response = await request.post('/users/get/', idUsuario);
        if (response && !response.error) {            
            if (response.user) {
                this.setState({
                    idUsuario: response.user.idUsuario, correo: response.user.correo,
                    telefono: response.user.telefono, nombre: response.user.nombre,
                    fecha: response.user.fecha,
                    admin: (response.user.admin === 1 ? true : false), estatus: response.user.estatus, 
                    empresa: response.user.empresa, loading: false
                });
                let checkbox = document.getElementById("admin");
                if (response.user.admin === 1) {
                    checkbox.checked = true;
                } else { checkbox.checked = false; }
            } else {
                this.setState({ loading: false, empty: true});
            }
        } else {
            this.setState({ loading: false, error: true });
        }
    }

    /**
     * @function: handleSubmit
     * @params: event: object, data: object (email: string, fullName: string, phone: string, password: string, admin: int)
     * @description: Función para realizar Login de la aplicación
    */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        if (isNaN(parseInt(this.state.telefono))) {
            this.setState({ loading: false });
            alert('El formato del teléfono es incorrecto');
        } else {
            const data = {
                nombre: this.state.nombre, telefono: this.state.telefono, estatus: this.state.estatus, 
                empresa: this.state.empresa, admin: this.state.admin, idUsuario: this.state.idUsuario
            };
            const response = await request.put('/users/update', data);
            if (response && !response.error) {
                if (response.updated) {
                    this.setState({ loading: false, showPopover: true, message: 'USUARIO ACTUALIZADO EXITOSAMENTE', type: 'success' });
                    setTimeout(() => {
                        this.setState({ showPopover: false });
                        this.props.history.push('/usuarios');
                    }, 4000);
                } else {
                    this.setState({ loading: false, showPopover: true, message: 'NO FUE POSIBLE ACTUALIZAR AL USUARIO', type: 'danger' });
                    setTimeout(() => {
                        this.setState({ showPopover: false });
                    }, 4000);
                }
            } else {
                this.setState({ loading: false, error: true, showPopover: true, message: 'NO FUE POSIBLE CONECTARSE A LOS SERVICIOS', type: 'danger' });
                setTimeout(() => {
                    this.setState({ showPopover: false });
                }, 4000);
            }
        }
    }

    handleExit() { this.props.history.push('/usuarios'); }

}

export default withRouter(Consumer(UsersEdit));