/**
 *  dashboard.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página del tablero de clientes
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import TableClients from '../components/tables/table-clients';
import Paginator from '../components/paginator';
import dayjs from 'dayjs';

// HTTP Client
import Request from '../config/http';
const request = new Request();

class Dashboard extends Component {

    state = {
        user: { idUsuario: 0, nombre: '', correo: '', admin: 0 }, clients: [], page: 1, loadingClients: false
    };

    render() {
        let pages;
        if (this.state.clients) {
            pages = Math.ceil(this.state.clients.length / 8);
        }
        return(
            <div className="dashboard">
                <Helmet>
                    <title>Clientes - Altura CRM</title>
                </Helmet>
                <Header breadcrumb = "Tablero > Clientes" type = { this.state.user.admin } />
                <div className="white-space-32"></div>
                <TableClients data = { this.state.clients } page = { this.state.page } loading = { this.state.loadingClients } admin = { this.state.user } />
                <Paginator pages = { pages } setPage = { this.setPage.bind(this) } scroll = { false } />
            </div>
        );
    }

    componentDidMount() {
        this.loadUser(false);
    }

    /**
     * @function: loadUser
     * @description: Función para recuperar la información del usuario activo
    */
    async loadUser(filtered) {
        this.setState({ loadingClients: true });
        const user = await this.props.context.loadUser();
        if (user) { 
            this.setState({ user: user.user });
            if (user.user.admin === 1) {
                this.getClients(filtered);
            } else {
                this.getClientsByUser(filtered, user.user.idUsuario);
            }
        }
    }

    /**
     * @function: getClients
     * @description: Función para recuperar la información de todos los clientes
    */
    async getClients(filtered) {
        if (filtered) {
            const response = await request.post('/clients/all','');
            if (response && !response.error) {
                this.setState({ clients: response.clients, loadingClients: false });
            } else {
                this.setState({ loadingClients: false, error: true });
            }
        } else {
            const response = await request.post('/clients/all','');
            if (response && !response.error) {
                this.filterClients(response.clients);
            } else {
                this.setState({ loadingClients: false, error: true });
            }
        }
        
    }

    /**
     * @function: getClientsByUser
     * @description: Función para recuperar la información de todos los clientes registrados por un usuario
    */
    async getClientsByUser(filtered, idUsuario) {
        if (filtered) {
            const response = await request.post('/clients/all/user', idUsuario);
            if (response && !response.error) {
                this.setState({ clients: response.clients, loadingClients: false });
            } else {
                this.setState({ loadingClients: false, error: true });
            }
        } else {
            const response = await request.post('/clients/all/user', idUsuario);
            if (response && !response.error) {
                this.filterClients(response.clients);
            } else {
                this.setState({ loadingClients: false, error: true });
            }
        }
    }


   /**
     * @function: filterClients
     * @description: Función para filtrar todos los clientes por sus fechas de vencimiento
    */
    filterClients(clients) {
        clients.map(async client => {
            const created = dayjs(client.fechaCreacion);
            const today = dayjs();
            const difference = today.diff(created, 'day');
            if (difference > 30 && client.estatus < 3 ) {
                const data = { estatus: 3, idCliente: client.idCliente };
                const response = await request.patch('/clients/estatus', data);
                if (response.error) {
                    this.setState({ clients, loadingClients: false, error: true });
                }
            } else {
                if (difference >= 25 && difference <= 30 && client.estatus < 2) {
                    const data = { estatus: 2, idCliente: client.idCliente };
                    const response = await request.patch('/clients/estatus', data);
                    if (response.error) {
                        this.setState({ clients, loadingClients: false, error: true });
                    }
                }
            }
            return null;
        });
        this.loadUser(true);
    }

    /**
     * @function: setPage
     * @param: page: int
     * @description: Función actualizar el valor de página del paginador
    */
    setPage(page) { this.setState({ page }); }

}

export default Consumer(Dashboard);