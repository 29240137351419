/**
 *  table-users.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Componente de tabla de usuarios
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';

class TableUsers extends Component {
    render(){
        let users = [], admin = '';
        if (this.props.data) { users = this.props.data; }
        if (this.props.admin) { admin = this.props.admin.admin; }
        return(
            <div className="table full justify-center">
                <div className="container">
                    <div className="table-content column full">
                        <div className="title">
                            <h4 className="color-darkgray">
                                USUARIOS
                            </h4>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="table-responsive">
                        {
                                this.props.loading ?
                                <div className="loading justify-center align-center">
                                    <i className="fas fa-spinner fa-spin fa-2x color-green"></i>
                                </div> :
                                (
                                <table className="wow animated fadeIn table-equal">
                                    <thead>
                                        <tr>
                                            <td className="name">Nombre</td>
                                            <td className="email">Correo</td>
                                            <td className="phone text-center">Teléfono</td>
                                            <td className="state text-center">Estado</td>
                                            <td className="more text-center">Ver más</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            users.slice(((this.props.page - 1)* 8),(this.props.page * 8)).map((user, key) => (
                                                <tr key = { key } >
                                                    <td className="capitalize">{ user.nombre }</td>
                                                    <td>{ user.correo }</td>
                                                    <td className="text-center">{ user.telefono }</td>
                                                    <td className="text-center">
                                                        {
                                                            user.estatus === 1 ? 
                                                            <span className="status color-white">
                                                                Activo
                                                            </span> :
                                                            <span className="status-danger color-white">
                                                                Inactivo
                                                            </span>
                                                        }
                                                    </td>
                                                    <td className="text-center btn-link">
                                                        <Link to = {{ pathname: 'usuarios/editar', state: { idUsuario: user.idUsuario, admin: admin } }} className="btn btn-primary btn-radius font-tiny">
                                                            VER MÁS
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(){
        new WOW.WOW({ live: false }).init();
    }
}

export default TableUsers;