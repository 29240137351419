/**
 *  http.js
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Cliente HTTP para todas las peticiones Web
*/

import request from "superagent";

const baseUrl = 'https://alturacancun.herokuapp.com';

class Request {

    get(url, data) {
        const result = request
            .get(baseUrl + url)
            .query(data)
            .set({ 'altura_cancun_api_key': 'c7858901a2515ac9a3b13e2cef8aed1ed42481779291a46943af3533c0eaf712' })
            .then(response => {
                return response.body;
            })
            .catch(error => {
                console.log("HTTP ERROR: ", error.message);
                return { message: error.message || "NO FUE POSIBLE CONECTARSE A LOS SERVICIOS", error: true };
            });
        return result;
    }

    post(url, data) {
        const result = request
            .post(baseUrl + url)
            .send(data)
            .set({ 'altura_cancun_api_key': 'c7858901a2515ac9a3b13e2cef8aed1ed42481779291a46943af3533c0eaf712' })
            .then(response => {
                return response.body;
            })
            .catch(error => {
                console.log("HTTP Error: ", error.message);
                return { message: error.message || "NO FUE POSIBLE CONECTARSE A LOS SERVICIOS", error: true };
            });
        return result;
    }

    put(url, data) {
        const result = request
            .put(baseUrl + url)
            .send(data)
            .set({ 'altura_cancun_api_key': 'c7858901a2515ac9a3b13e2cef8aed1ed42481779291a46943af3533c0eaf712' })
            .then(response => {
                return response.body;
            })
            .catch(error => {
                console.log("HTTP Error: ", error.message);
                return { message: error.message || "NO FUE POSIBLE CONECTARSE A LOS SERVICIOS", error: true };
            });
        return result;
    }

    patch(url, data) {
        const result = request
            .patch(baseUrl + url)
            .send(data)
            .set({ 'altura_cancun_api_key': 'c7858901a2515ac9a3b13e2cef8aed1ed42481779291a46943af3533c0eaf712' })
            .then(response => {
                return response.body;
            })
            .catch(error => {
                console.log("HTTP Error: ", error.message);
                return { message: error.message || "NO FUE POSIBLE CONECTARSE A LOS SERVICIOS", error: true };
            });
        return result;
    }
    
}

export default Request;
