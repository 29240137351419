/**
 *  clients-details.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página para ver información del cliente
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Popover from '../components/popover';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import WOW from 'wowjs';

// HTTP Client
import Request from '../config/http';
const request = new Request();

class ClientsDetails extends Component {

    state = { 
        user: { idUsuario: 0, nombre: '', correo: '', admin: 0 }, 
        idCliente: 0, nombre: '', apellidoPaterno: '', apellidoMaterno: '', correo: '', telefono: '', telefono2: '', medio: 'Página Web', idUsuario: 0, 
        situacion: 'Soltero', fecha: new Date(), hijos: 0, motivo: 'Alquiler', forma: 'Crédito', rango: '2800', habitaciones: 1, estatus: 1, ocupacion: '', 
        comentarios: [], comentario: '', currentComentario: 0, readOnly: true, loading: false, showPopover: false, type: 'success', message: '' 
    }

    render() {
        return(
            <div className="dashboard">
                <Helmet>
                    <title>Editar cliente - Altura CRM</title>
                </Helmet>
                <Header breadcrumb = "Tablero > Clientes > Editar" type = { this.state.user.admin } />
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-32"></div>
                        <form className="form-users column wow animated fadeIn" onSubmit = { this.handleSubmit.bind(this) }>
                            <div className="title">
                                <h4 className="color-darkgray">
                                    EDITAR CLIENTE
                                </h4>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="row-responsive full">
                                <div className="column full mr">
                                    <p className="weight-medium">
                                        Correo electrónico *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-at"></i>
                                        <input type="email" name="email" required minLength = "8" maxLength = "64" className="input full"
                                        value = { this.state.correo } disabled = { true } readOnly = { true } />
                                    </div>
                                </div>
                                <div className="row-responsive full">
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Nombre(s) *
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-font"></i>
                                            <input type="text" name="name" required minLength = "3" maxLength = "48" className="input full"
                                            value = { this.state.nombre } onChange = { (event) => { this.setState({ nombre: event.target.value }) } } />
                                        </div>
                                    </div>
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Apellido paterno *
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-font"></i>
                                            <input type="text" name="middlename" required minLength = "3" maxLength = "48" className="input full"
                                            value = { this.state.apellidoPaterno } onChange = { (event) => { this.setState({ apellidoPaterno: event.target.value }) } } />
                                        </div>
                                    </div>
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Apellido materno *
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-font"></i>
                                            <input type="text" name="lastname" required minLength = "3" maxLength = "48" className="input full"
                                            value = { this.state.apellidoMaterno } onChange = { (event) => { this.setState({ apellidoMaterno: event.target.value }) } } />
                                        </div>
                                    </div>              
                                </div>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="row-responsive full">
                                <div className="row-responsive full mr">
                                    <div className="column full">
                                        <p className="weight-medium">
                                            Teléfono *
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-phone"></i>
                                            <input type="text" name="phone" required minLength = "8" maxLength = "24" className="input full"
                                            value = { this.state.telefono } onChange = { (event) => { this.setState({ telefono: event.target.value }) } } />
                                        </div>
                                    </div>
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Teléfono secundario
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-phone"></i>
                                            <input type="text" name="phone2" minLength = "8" maxLength = "24" className="input full"
                                            value = { this.state.telefono2 } onChange = { (event) => { this.setState({ telefono2: event.target.value }) } } />
                                        </div>
                                    </div>
                                </div>
                                <div className="column full ml">
                                    <p className="weight-medium">
                                        Fecha de nacimiento *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-calendar-alt"></i>
                                        <DatePicker className="input full" peekNextMonth showMonthDropdown showYearDropdown dateFormat = "yyyy-MM-dd"
                                        selected = { Date.parse(this.state.fecha) } onChange = { (date) => this.setState({ fecha: date }) } required />
                                    </div>
                                </div>
                            </div>

                            <div className="white-space-24"></div>
                            <div className="row-responsive full">
                                <div className="row-responsive full">
                                    <div className="column full mr">
                                        <p className="weight-medium">
                                            Situación familiar
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-user-friends"></i>
                                            <select name="situation" className="input input-select full"
                                            value = { this.state.situacion } onChange = { (event) => { this.setState({ situacion: event.target.value }) } }>
                                                <option value="Soltero">Soltero</option>
                                                <option value="Casado">Casado</option>
                                                <option value="Divorciado">Divorciado</option>
                                                <option value="Unión libre">Unión Libre</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column full mr">
                                        <p className="weight-medium">
                                            Número de hijos
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-users"></i>
                                            <select name="children" className="input input-select full" 
                                            value = { this.state.hijos } onChange = { (event) => { this.setState({ hijos: event.target.value }) }} >
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-responsive full">
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Motivo de cambio
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-person-booth"></i>
                                            <select name="change" className="input input-select full"
                                            value = { this.state.motivo } onChange = { (event) => { this.setState({ motivo: event.target.value }) }} >
                                                <option value="Alquiler">Alquiler</option>
                                                <option value="Traslado / Profesionales">Traslado / Profesionales</option>
                                                <option value="Mejora de vivienda">Mejora de vivienda</option>
                                                <option value="Inversión">Inversión</option>
                                                <option value="Segunda residencia">Segunda residencia</option>
                                                <option value="Casarse / Independizarse">Casarse / Independizarse</option>
                                                <option value="Montar negocio">Montar negocio</option>
                                                <option value="Para hijos">Para hijos</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Ocupación
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-user-tie"></i>
                                            <input type="text" name="job" minLength = "8" maxLength = "48" className="input full"
                                            value = { this.state.ocupacion } onChange = { (event) => { this.setState({ ocupacion: event.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="white-space-24"></div>
                            <div className="row-responsive full">
                                <div className="row-responsive full">
                                    <div className="column full mr">
                                        <p className="weight-medium">
                                            Forma de pago
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-wallet"></i>
                                            <select name="payment" className="input input-select full"
                                            value = { this.state.forma } onChange = { (event) => { this.setState({ forma: event.target.value }) }} >
                                                <option value="Crédito">Crédito</option>
                                                <option value="Contado">Contado</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column full mr">
                                        <p className="weight-medium">
                                            Rango de inversión
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-exchange-alt"></i>
                                            <select name="range" className="input input-select full"
                                            value = { this.state.rango } onChange = { (event) => { this.setState({ rango: event.target.value }) }} >
                                                <option value="2800"> Menor a $2.8 MDP</option>
                                                <option value="2800:3200"> Entre a $2.8 y $3.2 MDP</option>
                                                <option value="3200:4300"> Entre a $3.2 y $4.3 MDP</option>
                                                <option value="4300"> Mayor a $4.3 MDP</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row-responsive full">
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Número de habitaciones
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-person-booth"></i>
                                            <select name="rooms" className="input input-select full"
                                            value = { this.state.habitaciones } onChange = { (event) => { this.setState({ habitaciones: event.target.value }) }} >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Procedencia del contacto
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-mail-bulk"></i>
                                            <select name="media" className="input input-select full"
                                            value = { this.state.medio } onChange = { (event) => { this.setState({ medio: event.target.value }) }} >
                                                <option value="Página Web">Página Web</option>
                                                <option value="Facebook">Facebook</option>
                                                <option value="Instagram">Instagram</option>
                                                <option value="Twitter">Twitter</option>
                                                <option value="Recomendaciones">Referencia / Recomendaciones</option>
                                                <option value="Caseta de ventas">Caseta de ventas</option>
                                                <option value="Google">Google</option>
                                                <option value="otro">Otro</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="btn-container row">
                                {
                                    this.state.estatus === 3 ?
                                    <button type="button" className="btn btn-primary btn-radius color-white" onClick = { this.handleReactivate.bind(this) }>
                                        {
                                            this.state.loading ?
                                            <span className="font-mini color-white">
                                                <i className="fas fa-spinner fa-spin"></i> &nbsp; ENVIANDO SOLICITUD
                                            </span>
                                            :
                                            <span className="font-mini color-white">
                                                <i className="fas fa-exclamation-circle"></i> &nbsp; SOLICITAR REACTIVACIÓN
                                            </span>
                                        }
                                    </button>  
                                    : this.state.estatus === 4 ? 
                                        <button type="button" className="btn btn-warning btn-radius color-white">
                                            <span className="font-mini color-white">
                                                <i className="fas fa-lock"></i> &nbsp; SOLICITUD ENVIADA
                                            </span>
                                        </button> 
                                    :
                                    <button type="submit" className="btn btn-primary btn-radius color-white">
                                        {
                                            this.state.loading ?
                                            <span className="font-mini color-white">
                                                <i className="fas fa-spinner fa-spin"></i> &nbsp; GUARDANDO CLIENTE
                                            </span>
                                            :
                                            <span className="font-mini color-white">
                                                <i className="fas fa-check"></i> &nbsp; GUARDAR CLIENTE
                                            </span>
                                        }
                                    </button>        
                                }
                                <button type = "button" className="btn btn-danger btn-radius color-white" onClick = { this.handleExit.bind(this) }>
                                    <i className="fas fa-times"></i>
                                    &nbsp; CANCELAR
                                </button>
                            </div>
                        </form>

                        <div className="white-space-24"></div>                        
                        <div className="form-users column">
                            <div className="title justify-between">
                                <h4 className="color-darkgray">
                                    Histórico de comentarios
                                </h4>
                                <button type="button" onClick = { this.handleCleanCommentary.bind(this) } className="btn btn-secondary btn-small btn-radius">
                                    <i className="fas fa-plus"></i> Nuevo comentario
                                </button>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="input-container">
                                <i className="fas fa-caret-square-down"></i>
                                <select name="media" className="input input-select full" value = { this.state.currentComentario }
                                onChange = { this.handleCommentary.bind(this) }>
                                    {
                                    this.state.comentarios ?
                                        this.state.comentarios.map((item, key) => (
                                            <option value = { item.idComentario } key = { key }>
                                                { `${item.nombre} - ${dayjs(item.fecha).format('YYYY-MM-DD')} - #${item.idComentario}` }
                                            </option>
                                        )) : null
                                    }
                                </select>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="row-responsive full">
                                <div className="input-container full column">
                                    <i className="fas fa-mail-bulk"></i>
                                    <textarea className="input full" maxLength="300" rows="6" name="comments" placeholder="Comentarios (Opcional)"
                                    value = { this.state.comentario } onChange = { (event) => { this.setState({ comentario: event.target.value }) }} readOnly = { this.state.readOnly }></textarea>
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="btn-container row">
                                <button type="button" className="btn btn-primary btn-radius color-white" onClick = { this.handleAddCommentary.bind(this) }>
                                    {
                                        this.state.loading ?
                                        <span className="font-mini color-white">
                                            <i className="fas fa-spinner fa-spin"></i> &nbsp; GUARDANDO COMENTARIO
                                        </span>
                                        :
                                        <span className="font-mini color-white">
                                            <i className="fas fa-check"></i> &nbsp; GUARDAR COMENTARIO
                                        </span>
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="white-space-24"></div>
                        { this.state.showPopover && <Popover type = { this.state.type } message = { this.state.message } /> }
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(){
        if (this.props.location.state) {
            this.loadUser();
        }
        new WOW.WOW({ live: false }).init();
    }

    /**
     * @function: loadUser
     * @description: Función para recuperar la información del usuario activo
    */
   async loadUser() {
        this.setState({ loadingUsers: true });
        const user = await this.props.context.loadUser();
        if (user){ 
            this.setState({ user: user.user });
            this.getClient(this.props.location.state.idCliente);
        }
    }

    /**
     * @function: getClient
     * @param: idCliente: int
     * @description: Función para obtener los datos del usuario
    */
    async getClient(idCliente) {
        this.setState({ loading: true });
        const response = await request.post('/clients/get', idCliente);
        if (response && !response.error) {            
            if (response.client) {
                this.setState({
                    idCliente: response.client.idCliente, nombre: response.client.nombre, apellidoPaterno: response.client.apellidoPaterno, apellidoMaterno: response.client.apellidoMaterno, correo: response.client.correo, telefono: response.client.telefono, telefono2: response.client.telefono2, medio: response.client.medio, idUsuario: response.client.idUsuario, situacion: response.client.situacion, 
                    hijos: response.client.hijos, motivo: response.client.motivo, forma: response.client.forma, rango: response.client.rango, 
                    habitaciones: response.client.habitaciones,estatus: response.client.estatus, ocupacion: response.client.ocupacion,
                    loading: false
                }); 
                this.setState({ fecha: dayjs(response.client.fecha).format('YYYY-MM-DD') });
                this.getCommentaries(idCliente);
            } else {
                this.setState({ loading: false, empty: true});
            }
        } else {
            this.setState({ loading: false, error: true });
        }
    }

    /**
     * @function: handleSubmit
     * @params: event: object, data: object
     * @description: Función para realizar Login de la aplicación
    */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        if (isNaN(parseInt(this.state.telefono))) {
            this.setState({ loading: false });
            alert("El formato del teléfono no es correcto");
        } else {
            const data = {
                idCliente: this.state.idCliente, nombre: this.state.nombre, apellidoPaterno: this.state.apellidoPaterno, apellidoMaterno: this.state.apellidoMaterno, 
                telefono: this.state.telefono, telefono2: this.state.telefono2, medio: this.state.medio, idUsuario: this.state.idUsuario, situacion: this.state.situacion, 
                hijos: this.state.hijos, motivo: this.state.motivo, forma: this.state.forma, rango: this.state.rango, habitaciones: this.state.habitaciones, 
                estatus: this.state.estatus, ocupacion: this.state.ocupacion, fecha: this.state.fecha
            };
            
            const response = await request.put('/clients/update', data);
            if (response && !response.error) {
                if (response.updated) {
                    this.setState({ loading: false, showPopover: true, message: 'CLIENTE ACTUALIZADO EXITOSAMENTE', type: 'success' });
                    setTimeout(() => {
                        this.setState({ showPopover: false });
                        this.props.history.push('/clientes');
                    }, 3500);
                } else {
                    this.setState({ loading: false, showPopover: true, message: 'NO FUE POSIBLE ACTUALIZAR AL CLIENTE', type: 'danger' });
                    setTimeout(() => {
                        this.setState({ showPopover: false });
                    }, 3500);
                }
            } else {
                this.setState({ loading: false, error: true, showPopover: true, message: 'NO FUE POSIBLE CONECTARSE A LOS SERVICIOS', type: 'danger' });
                setTimeout(() => {
                    this.setState({ showPopover: false });
                }, 3500);
            }
        }
    }

    handleExit() { this.props.history.push('/clientes'); }

        /**
     * @function: getCommentaries
     * @param: idCliente: int
     * @description: Función para obtener los comentarios del cliente
    */
    async getCommentaries(idCliente) {    
        const response = await request.post('/clients/get/comentarios', idCliente);
        if (response && !response.error) {            
            if (response.commentaries) {
                this.setState({ 
                    comentarios: response.commentaries, currentComentario: response.commentaries[0].idComentario, 
                    comentario: response.commentaries[0].comentario, loading: false });
            } else {
                this.setState({ loading: false, empty: true});
            }
        } else {
            this.setState({ loading: false, error: true });
        }
    }

    /**
     * @function: handleCommentary
     * @description: Función para seleccionar el comentario activo y mostrarlo
    */
    handleCommentary(event) {
        this.setState({ currentComentario: parseInt(event.target.value) });
        const filterData = this.state.comentarios.filter(comentario => comentario.idComentario === parseInt(event.target.value));
        this.setState({ comentario: filterData[0].comentario, readOnly: true });
    }
    
    /**
     * @function: handleCleanCommentary
     * @description: Función para habilitar un nuevo comentario
    */
    handleCleanCommentary() {
        this.setState({ readOnly: false, comentario: '' });
    }

    /**
     * @function: handleAddCommentary
     * @description: Función para añadir un nuevo comentario para el cliente
    */
    async handleAddCommentary() {
        const data = { idCliente: this.state.idCliente, idUsuario: this.state.user.idUsuario, comentario: this.state.comentario };
        const response = await request.post('/clients/add/comentario', data);
        if (response && !response.error) {
            if (response.created) {
                this.setState({ loading: false, showPopover: true, message: 'COMENTARIO AÑADIDO EXITOSAMENTE', type: 'success' });
                setTimeout(() => {
                    this.setState({ showPopover: false });
                    this.props.history.push('/clientes');
                }, 3500);
            } else {
                this.setState({ loading: false, showPopover: true, message: 'NO FUE POSIBLE AÑADIR EL COMENTARIO', type: 'danger' });
                setTimeout(() => {
                    this.setState({ showPopover: false });
                }, 3500);
            }
        } else {
            this.setState({ loading: false, error: true, showPopover: true, message: 'NO FUE POSIBLE CONECTARSE A LOS SERVICIOS', type: 'danger' });
            setTimeout(() => {
                this.setState({ showPopover: false });
            }, 3500);
        }
    }

    /**
     * @function: handleReactivate
     * @description: Función para solicitar reactivación
    */
    async handleReactivate() {
        const idCliente = this.state.idCliente;
        const response = await request.patch('/clients/reactivate', idCliente);
        if (response && !response.error) {
            if (response.updated) {
                this.setState({ loading: false, showPopover: true, message: 'SOLICITUD ENVIADA', type: 'success' });
                setTimeout(() => {
                    this.setState({ showPopover: false });
                    this.props.history.push('/clientes');
                }, 3500);
            } else {
                this.setState({ loading: false, showPopover: true, message: 'NO FUE POSIBLE ENVIAR LA SOLICITUD', type: 'danger' });  
                setTimeout(() => {
                    this.setState({ showPopover: false });
                }, 3500);  
            }
        } else {
            this.setState({ loading: false, showPopover: true, message: 'NO FUE POSIBLE CONECTARSE A LOS SERVICIOS', type: 'danger' });
            setTimeout(() => {
                this.setState({ showPopover: false });
            }, 3500);
        }
    }

}

export default withRouter(Consumer(ClientsDetails));