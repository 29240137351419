/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Componente Header
*/

import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Consumer } from '../../context';

// Imágenes
import Logo from '../../img/logocolor.jpg';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: window.localStorage.getItem('AlturaCRM_Nombre')
        }
    }

    render() {
        return(
            <header className="full column">
                 <div className="header-top justify-center">
                     <div className="container row-responsive">
                         <div className="responsive-img logo">
                            <img src = { Logo } alt="Altura Cancún Logo" title = "Altura Cancún Logo" />
                         </div>
                        <div className="controls align-center">
                            <div className="item">
                                <p className="color-white font-text weight-semi">
                                    Hola, { this.state.name }
                                </p>
                            </div>
                            <div className="item">
                                <button type = "button" className="align-center color-white weight-semi"
                                onClick = { this.handleLogout.bind(this) }>
                                    Salir &nbsp;
                                    <i className="fas fa-sign-in-alt"></i>
                                </button>
                            </div>
                        </div>
                     </div>
                 </div>
                 <div className="header-middle justify-center">
                    <div className="container">
                        <div className="breadcrumbs">
                            <p className="font-tiny weight-medium color-gray">
                                { this.props.breadcrumb }
                            </p>
                        </div>
                        <div className="navigation wow animated fadeIn faster row">
                            <div className="item">
                                <NavLink exact to ="/clientes" className="weight-medium color-gray" 
                                activeClassName = "item-active" >
                                    <i className="fas fa-user-friends"></i>
                                    &nbsp; Clientes
                                </NavLink>
                            </div>
                            <div className="item">
                                <NavLink exact to ="/clientes/nuevo" className="weight-medium color-gray" 
                                activeClassName = "item-active" >
                                    <i className="fas fa-user-plus"></i>
                                    &nbsp; Añadir clientes
                                </NavLink>
                            </div>
                            {
                                this.props.type === 1 ?
                                <div className="item">
                                    <NavLink exact to ="/clientes/archivados" className="weight-medium color-gray"
                                    activeClassName = "item-active" >
                                        <i className="fas fa-bookmark"></i>
                                        &nbsp; Archivados
                                    </NavLink>
                                </div> :
                                null
                            }
                            {
                                this.props.type === 1 ?
                                <div className="item">
                                    <NavLink exact to ="/usuarios" className="weight-medium color-gray"
                                    activeClassName = "item-active" >
                                        <i className="fas fa-user-check"></i>
                                        &nbsp; Usuarios
                                    </NavLink>
                                </div> :
                                null
                            }
                            {
                                this.props.type === 1 ?
                                <div className="item">
                                    <NavLink exact to ="/usuarios/nuevo" className="weight-medium color-gray" 
                                    activeClassName = "item-active" >
                                        <i className="fas fa-user-plus"></i>
                                        &nbsp; Añadir usuario
                                    </NavLink>
                                </div> :
                                null
                            }
                        </div>
                    </div>
                 </div>
            </header>
        );
    }

    componentDidMount() {
    }

    /**
     * @name: handleLogout
     * @description: Función para finalizar la sesión de usuario
    */
    handleLogout() {
        this.props.context.logout();
        window.location = '/';
    }

}

export default withRouter(Consumer(Header));