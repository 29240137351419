/**
 *  restore.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página de restaurar contraseña
 */

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Consumer } from '../context';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';

// Imágenes
import Logo from '../img/logoblanco.jpg';

// HTTP Client
import Request from '../config/http';
const request = new Request();

 class Restore extends Component {

    state = {
        loading: false,
        message: null
    }

    render() {
        return(
            <div className="login align-center">
                <Helmet>
                    <title>Restaurar contraseña - Altura CRM</title>
                </Helmet>
                <div className="justify-center align-center">
                    <div className="container">
                        <div className="justify-center align-center">
                            <form className="login-area column" onSubmit = { this.handleSubmit.bind(this) }>
                                <div className="responsive-img justify-center align-center">
                                    <img src = { Logo } alt="Altura logo" title="Altura logo"/>
                                </div>
                                <div className="white-space-16"></div>
                                <h3 className="text-center color-darkgray">
                                    RESTAURAR CONTRASEÑA
                                </h3>
                                <div className="white-space-24"></div>
                                <div className="input-group row justify-center">
                                    <div className="icon align-center">
                                        <i className="fas fa-at"></i>                            
                                    </div>
                                    <input type="email" minLength = "6" maxLength = "64" className="input" 
                                    name="email" placeholder="Correo electrónico" />
                                </div>
                                <div className="white-space-16"></div>
                                <div className="btn-container justify-center">
                                    <button type="submit" className="btn btn-radius btn-primary">
                                        {
                                            this.state.loading ? 
                                            <i className="fas fa-spinner fa-spin color-white"></i> :
                                            "RESTAURAR"
                                        }
                                    </button>
                                </div>
                                <div className="white-space-8"></div>
                                {
                                    this.state.message ? 
                                    <p className="text-center weight-medium">
                                        { this.state.message }
                                    </p> :
                                    <p className="color-white text-center">!Buh!</p>
                                }
                                <div className="white-space-16"></div>
                                <div className="justify-center">
                                    <Link to="/" className="text-center font-mini">
                                        Regresar a inicio
                                    </Link>            
                                </div>
                                <div className="white-space-8"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * @function: handleSubmit
     * @param: event: object
     * @description: Función para validar la existencia del usuario
    */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;
        const data = {
            date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            email: form.email.value
        }
        const response = await request.post('/users/restore', data);
        if (response && !response.error) {
            if (response.send) {
                this.setState({ message: 'Enviamos un correo con instrucciones para restablecer tu contraseña' });
            } else {
                this.setState({ message: 'No existe un usuario vinculado con el correo proporcionado' });
            }
            this.setState({ loading: false });
        } else {
            this.setState({ loading: false, error: response.error, message: 'No fue posible enviar un token de restauración' });
        }
    }

}

 export default withRouter(Consumer(Restore));