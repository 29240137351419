/**
 *  modal.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Componente de Modal general
*/

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class Modal extends Component {
    
    render() {
        if (this.props.isOpen) {
            return(
                ReactDOM.createPortal(
                    <div className = "modal justify-center align-center">
                        <div className="modal-content column">
                            <div className="title">
                                <h2 className="color-darkgray text-center">Exportar a Excel</h2>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="subtitle">
                                <h5 className="color-darkgray text-center">Selecciona un rango de fechas a exportar</h5>
                            </div>
                            <div className="white-space-24"></div>
                            { this.props.children }
                        </div>
                    </div>, 
                document.getElementById('modal'))
            );
        } else {
            return false;
        }
    }    
}

export default Modal;