/**
 *  table-archive.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Componente de tabla de archivos
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactExport from 'react-data-export';
import dayjs from 'dayjs';
import WOW from 'wowjs';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class TableArchive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initial: new Date('2018-01-02'), final: new Date(),
            isOpen: false, loading: false, error: false, query: '', data: [],
            loadingExcel: false, hide: true, dataExcel: null, filterData: []
        }
    }

    render() {
        let admin = '';
        if (this.props.admin) { admin = this.props.admin.admin; }
        return(
            <div className="table table-clients full justify-center">
                <div className="container">
                    <div className="table-content column full">
                        <div className="title row">
                            <h4 className="color-darkgray">
                                CLIENTES ARCHIVADOS
                            </h4>
                            <div className="excel row-responsive">
                                <div className="search">
                                    <input type="text" className="input" name="search" value = { this.state.query }
                                    onChange = { this.handleSearch.bind(this) } placeholder = "Buscar" />
                                </div>
                                <ExcelFile element = {
                                    <button type = "button" className="btn btn-primary btn-radius color-white" disabled = { this.props.data.length > 0 ? false : true } onClick = { () => { setTimeout(() => {
                                        this.setState({ isOpen: false, hide: true, loadingExcel: false })
                                    }, 2000)} }>
                                        <i className="fas fa-file-excel"></i>&nbsp; DESCARGAR EXCEL
                                    </button>
                                } filename = 'Altura Clientes Archivados'>
                                    <ExcelSheet filename = { 'Altura-clientes'} data = { this.state.data } name = { 'Clientes' }>
                                        <ExcelColumn label = "ID" value = "idCliente" />
                                        <ExcelColumn label = "Nombre" value = "nombre" />
                                        <ExcelColumn label = "Apellido Paterno" value = "apellidoPaterno" />
                                        <ExcelColumn label = "Apellido Materno" value = "apellidoMaterno" />
                                        <ExcelColumn label = "Fecha nacimiento" value = { (col) =>  col.fecha ? `${dayjs(col.fecha).format('YYYY-MM-DD')}` : '' } />
                                        <ExcelColumn label = "Teléfono" value = "telefono" />
                                        <ExcelColumn label = "Teléfono 2" value = "telefono2" />
                                        <ExcelColumn label = "Email" value = "correo" />
                                        <ExcelColumn label = "Fecha alta" value = { (col) =>  col.fechaCreacion ? `${dayjs(col.fechaCreacion).format('YYYY-MM-DD')}` : '' } />
                                        <ExcelColumn label = "Situación familiar" value = "situacion" />
                                        <ExcelColumn label = "Ocupación" value = "ocupacion" />
                                        <ExcelColumn label = "Medio" value = "medio" />
                                        <ExcelColumn label = "Motivo de cambio" value = "motivo" />
                                        <ExcelColumn label = "Rango de inversión" value = "rango" />
                                        <ExcelColumn label = "Forma de pago" value = "forma" />
                                        <ExcelColumn label = "Número de habitaciones" value = "habitaciones" />
                                        <ExcelColumn label = "ID Usuario" value = "idUsuario" />
                                        <ExcelColumn label = "Usuario" value = "usuarioNombre" />
                                        <ExcelColumn label = "Comentarios" value = "comentario" />
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="table-responsive">
                            {
                                this.props.loading ?
                                <div className="loading justify-center align-center">
                                    <i className="fas fa-spinner fa-spin fa-2x color-green"></i>
                                </div> :
                                (
                                <table className="wow animated fadeIn table-equal">
                                    <thead>
                                        <tr>
                                            <td className="id">ID</td>
                                            <td className="name">Nombre</td>
                                            <td className="email">Correo</td>
                                            <td className="phone text-center">Teléfono</td>
                                            <td className="state text-center">Estado</td>
                                            <td className="more text-center">Ver más</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.data.slice(((this.props.page - 1)* 8),(this.props.page * 8)).map((client, key) => (
                                                <tr key = { key } >
                                                    <td>{ client.idCliente }</td>
                                                    <td className="capitalize">{ client.nombre }</td>
                                                    <td>{ client.correo }</td>
                                                    <td className="text-center">{ client.telefono }</td>
                                                    <td className="text-center">
                                                        {
                                                            client.estatus === 3 ?
                                                            <span className="status-danger color-white">
                                                                Archivado
                                                            </span> :
                                                            <span className="status-info color-white">
                                                                Solicitado
                                                            </span>
                                                        }
                                                    </td>
                                                    <td className="text-center btn-link">
                                                        <Link to = {{ pathname: 'editar', state: { idCliente: client.idCliente, admin: admin } }} 
                                                        className="btn btn-primary btn-radius font-tiny">
                                                            VER MÁS
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(){
        new WOW.WOW({ live: false }).init();
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data, loading: true });
        }
    }    

    /**
     * @function: handleSearch
     * @description: Función para buscar clientes
    */
    handleSearch(event) {
        const { data } = this.props;
        this.setState({ query: event.target.value });
        if (event.target.value <= 0) {
            this.setState({ data });
        } else {
            const filterValue = data.filter(element => {
                return `${element.nombre} ${element.correo}`.toLowerCase().includes(this.state.query.toLowerCase())
            });
            this.setState({ data: filterValue });
        }
    }

}

export default TableArchive;