/**
 *  restore-change.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página de establecer contaseña
 */

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Consumer } from '../context';
import { Helmet } from 'react-helmet';
import Popover from '../components/popover';

// Imágenes
import Logo from '../img/logoblanco.jpg';

// HTTP Client
import Request from '../config/http';
const request = new Request();

 class RestoreChange extends Component {

    state = {
        loading: false, message: null, idUsuario: 0, token: null, loadingChange: false, showPopover: false, type: 'success'
    }

    render() {
        return(
            <div className="login align-center">
                <Helmet>
                    <title>Nueva contraseña - Altura CRM</title>
                </Helmet>
                <div className="justify-center align-center">
                    <div className="container">
                        <div className="justify-center align-center">
                            <form className="login-area column" onSubmit = { this.handleSubmit.bind(this) }>
                                {
                                this.state.loading ?
                                    <div className="justify-center align-center">
                                        <i className="fas fa-spinner fa-spin fa-2x color-green"></i>
                                    </div>
                                :
                                    <div className="column">
                                        <div className="responsive-img justify-center align-center">
                                            <img src = { Logo } alt="Altura logo" title="Altura logo"/>
                                        </div>
                                        <div className="white-space-16"></div>
                                        <h3 className="text-center color-darkgray">
                                            CAMBIAR CONTRASEÑA
                                        </h3>
                                        <div className="white-space-24"></div>
                                        <div className="input-group row justify-center">
                                            <div className="icon align-center">
                                                <i className="fas fa-key"></i>                            
                                            </div>
                                            <input type="password" className="input" name="password" placeholder="Nueva contraseña" minLength = "8" maxLength = "16" />
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="input-group row justify-center">
                                            <div className="icon align-center">
                                                <i className="fas fa-key"></i>                            
                                            </div>
                                            <input type="password" className="input" name="repassword" placeholder="Repetir contraseña" minLength = "8" maxLength = "16" />
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="btn-container justify-center">
                                            <button type="submit" className="btn btn-radius btn-primary">
                                                {
                                                    this.state.loadingChange ? 
                                                    <i className="fas fa-spinner fa-spin color-white"></i> :
                                                    "ACTUALIZAR"
                                                }
                                                
                                            </button>
                                        </div>
                                        <div className="white-space-8"></div>
                                        {
                                            this.state.message ? 
                                            <p className="text-center weight-medium">
                                                { this.state.message }
                                            </p> :
                                            <p className="color-white text-center">!Buh!</p>
                                        }
                                        <div className="white-space-16"></div>
                                        <div className="justify-center">
                                            <Link to="/" className="text-center font-mini">
                                                Regresar a inicio
                                            </Link>            
                                        </div>
                                    <div className="white-space-8"></div>
                                    </div>
                            }
                            </form>
                        </div>
                    </div>
                </div>
                {
                    this.state.showPopover ? 
                    <Popover type = { this.state.type } message = { this.state.message } /> : null                            
                }
            </div>
        );
    }

    componentDidMount() {
        this.setState({ loading: true });
        let token = null;
        if (this.props.match.params) {
            token = this.props.match.params.token;
            this.validateToken(token);
        } else {
            this.setState({ loading: false });
            this.props.history.push('/');
        }
    }

    /**
     * @function: validateToken
     * @param: event: object
     * @description: Función para validar la autenticidad del token
    */
    async validateToken(token) {
        const response = await request.post('/users/restore/validate', { token });
        if (response && !response.error) {
            if (response.valid) {
                this.setState({ loading: false, idUsuario: response.idUsuario, token: response.token });
            } else {
                this.setState({ loading: false });
                this.props.history.push('/');
            }
        } else { 
            this.setState({ loading: false, error: true });
            this.props.history.push('/');
        }
    }

    /**
     * @function: handleSubmit
     * @param: event: object
     * @description: Función para actualizar la contraseña de usuario
    */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loadingChange: true });
        const form = event.target;
        if (form.password.value === form.repassword.value) {
            const data = {
                idUsuario: this.state.idUsuario,
                token: this.state.token,
                password: form.password.value,
            };
            const response = await request.put('/users/restore/update', data);
            if (response && !response.error) {
                if (response.updated) {
                    this.setState({ showPopover: true, message: 'Contraseña actualizado exitosamente, serás redireccionado', type: 'success', loadingChange: false });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 3500);
                } else {
                    this.setState({ showPopover: true, type: 'danger', message: 'No fue posible actualizar la contraseña de usuario', loadingChange: false });
                }
            } else {
                this.setState({ loadingChange: false, error: true });
            }
        } else {
            alert('Las contraseñas no coinciden');
            this.setState({ loadingChange: false });
        }
    }
}

 export default withRouter(Consumer(RestoreChange));