/**
 *  users-add.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página para añadir usuario
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Popover from '../components/popover';
import WOW from 'wowjs';

// HTTP Client
import Request from '../config/http';
const request = new Request();

class UsersAdd extends Component {

    state = { user: { idUsuario: 0, nombre: '', correo: '', admin: 0 }, admin: false, loading: false, showPopover: false, type: 'success', message: '' }

    render() {
        return(
            <div className="dashboard">
                <Helmet>
                    <title>Añadir usuarios - Altura CRM</title>
                </Helmet>
                <Header breadcrumb = "Tablero > Usuarios > Nuevo" type = { this.state.user.admin } />
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-32"></div>
                        <form className="form-users column wow animated fadeIn" onSubmit = { this.handleSubmit.bind(this) }>
                            <div className="title">
                                <h4 className="color-darkgray">
                                    AÑADIR USUARIO
                                </h4>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="row-responsive full">
                                <div className="column full mr">
                                    <p className="weight-medium">
                                        Correo electrónico *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-at"></i>
                                        <input type="email" name="email" required minLength = "8" maxLength = "64" className="input full" />
                                    </div>
                                </div>
                                <div className="column full ml">
                                    <p className="weight-medium">
                                        Nombre completo *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-font"></i>
                                        <input type="text" name="fullName" required minLength = "8" maxLength = "48" className="input full" />
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="row-responsive full">
                                <div className="column full mr">
                                    <p className="weight-medium">
                                        Teléfono *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-phone"></i>
                                        <input type="text" name="phone" required minLength = "8" maxLength = "24" className="input full" />
                                    </div>
                                </div>
                                <div className="column full ml">
                                    <p className="weight-medium">
                                        Empresa *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-building"></i>
                                        <input type="text" name="company" required minLength = "8" maxLength = "24" className="input full" />
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="row-responsive full">
                                <div className="column full mr">
                                    <p className="weight-medium">
                                        Contraseña *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-key"></i>
                                        <input type="password" name="password" required minLength = "8" maxLength = "24" className="input full" />
                                    </div>
                                </div>
                                <div className="column full ml">
                                    <p className="weight-medium">
                                        Repetir contraseña *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-key"></i>
                                        <input type="password" name="repassword" required minLength = "8" maxLength = "24" className="input full" />
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="row-responsive full">
                                <div className="row align-center full ml">
                                    <label className="checkbox-container mr">
                                        ¿Es administrador?
                                        <input type="checkbox" id="admin" name="admin" defaultChecked = { this.state.admin } 
                                        onChange = { (event) => this.setState({ admin: event.target.checked }) } />
                                        <span className="checkmark"></span>
                                    </label>            
                                </div>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="btn-container row">
                                <button type="submit" className="btn btn-primary btn-radius color-white">
                                    {
                                        this.state.loading ?
                                        <span className="font-mini color-white">
                                            <i className="fas fa-spinner fa-spin"></i> &nbsp; GUARDANDO USUARIO
                                        </span>
                                        :
                                        <span className="font-mini color-white">
                                            <i className="fas fa-check"></i> &nbsp; GUARDAR USUARIO
                                        </span>
                                    }
                                </button>
                                <button type = "button" className="btn btn-danger btn-radius color-white" onClick = { this.handleExit.bind(this) }>
                                    <i className="fas fa-times"></i>
                                    &nbsp; CANCELAR
                                </button>
                            </div>
                        </form>
                        <div className="white-space-32"></div>
                        { this.state.showPopover && <Popover type = { this.state.type } message = { this.state.message } /> }
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(){
        new WOW.WOW({ live: false }).init();
        this.loadUser();
    }

    /**
     * @function: loadUser
     * @description: Función para recuperar la información del usuario activo
    */
   async loadUser() {
        this.setState({ loadingUsers: true });
        const user = await this.props.context.loadUser();
        if (user){ 
            this.setState({ user: user.user });
            if (user.user.admin === 0) {
                this.props.history.push('/clientes');
            }
        }
    }

    /**
     * @function: handleSubmit
     * @params: event: object, data: object (email: string, fullName: string, phone: string, password: string, admin: int)
     * @description: Función para realizar Login de la aplicación
    */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;
        const phone = parseInt(form.phone.value);
        if (form.password.value === form.repassword.value) {
            if (isNaN(phone)) {
                alert('El formato del teléfono es incorrecto');
                this.setState({ loading: false });
            } else {
                const data = {
                    email: form.email.value, fullName: form.fullName.value, phone: phone, 
                    password: form.password.value, admin: this.state.admin, company: form.company.value
                };
                const response = await request.post('/users/add', data);
                if (response && !response.error) {
                    if (response.status.status === 1) {
                        this.setState({ loading: false, showPopover: true, message: 'USUARIO REGISTRADO EXITOSAMENTE', type: 'success' });
                        setTimeout(() => {
                            this.setState({ showPopover: false });
                            this.props.history.push('/usuarios');
                        }, 3500);
                    } else {
                        this.setState({ loading: false, showPopover: true, message: 'EL CORREO YA SE ENCUENTRA EN USO', type: 'danger' });
                        setTimeout(() => {
                            this.setState({ showPopover: false });
                        }, 3500);
                    }
                } else {
                    this.setState({ loading: false, error: true, showPopover: true, message: 'NO FUE POSIBLE CONECTARSE A LOS SERVICIOS', type: 'danger' });
                    setTimeout(() => {
                        this.setState({ showPopover: false });
                    }, 3500);
                }
            }
        } else {
            alert('Las contraseñas no coinciden');
            this.setState({ loading: false });
        }
    }

    handleExit() {
        this.props.history.push('/usuarios');
    }

}

export default withRouter(Consumer(UsersAdd));