/**
 *  users.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página de todos los usuarios registrados
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import TableUsers from '../components/tables/table-users';
import Paginator from '../components/paginator';

// HTTP Client
import Request from '../config/http';
const request = new Request();

class Users extends Component {

    state = {
        user: { idUsuario: 0, nombre: '', correo: '', admin: 0 }, users: [], page: 1, loadingUsers: false
    };

    render(){
        let pages;
        if (this.state.users) {
            pages = Math.ceil(this.state.users.length / 8);
        }
        return(
            <div className="dashboard">
                <Helmet>
                    <title>Usuarios - Altura CRM</title>
                </Helmet>
                <Header breadcrumb = "Tablero > Usuarios" type = { this.state.user.admin } />
                <div className="white-space-32"></div>
                <TableUsers data = { this.state.users } page = { this.state.page } loading = { this.state.loadingUsers } admin = { this.state.user } />
                <Paginator pages = { pages } setPage = { this.setPage.bind(this) } scroll = { false } />
            </div>
        );
    }

    componentDidMount() {
        this.loadUser();
    }

    /**
     * @function: loadUser
     * @description: Función para recuperar la información del usuario activo
    */
    async loadUser() {
        this.setState({ loadingUsers: true });
        const user = await this.props.context.loadUser();
        if (user){ 
            this.setState({ user: user.user });
            if (user.user.admin === 1) {
                this.getUsers();
            } else {
                this.props.history.push('/clientes');
            }
        }
    }

    /**
     * @function: getUsers
     * @description: Función para recuperar la información de todos los usuarios
    */
    async getUsers() {
        const response = await request.post('/users/all','');
        if (response && !response.error) {
            this.setState({ users: response.users, loadingUsers: false });
        } else {
            this.setState({ loadingUsers: false, error: true });
        }
    }

    /**
     * @function: setPage
     * @param: page: int
     * @description: Función actualizar el valor de página del paginador
    */
   setPage(page) {
        this.setState({ page });
    }

}

export default withRouter(Consumer(Users));