/**
 *  login-validate.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página validación de primer usuario
 */

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Consumer } from '../context';
import { Helmet } from 'react-helmet';
import Popover from '../components/popover';
import WOW from 'wowjs';

// Imágenes
import Logo from '../img/logoblanco.jpg';

// HTTP Client
import Request from '../config/http';
const request = new Request();

 class LoginValidate extends Component {

    state = {
        idUsuario: 0, loading: true, message: null, loadingChange: false, showPopover: false, type: 'success'
    }

    render() {
        return(
            <div className="login align-center">
                <Helmet>
                    <title>Validación - Altura CRM</title>
                </Helmet>
                <div className="justify-center align-center wow fadeIn">
                    <div className="container">
                        <div className="justify-center align-center">
                            <form className="login-area column" onSubmit = { this.handleSubmit.bind(this) }>
                                {
                                this.state.loading ?
                                    <div className="column">
                                        <div className="justify-center align-center wow fadeIn" data-wow-delay="0.20s">
                                            <i className="fas fa-lock fa-2x color-green"></i>
                                        </div>
                                        <div className="white-space-24"></div>
                                        <h4 className="text-center color-darkgray wow fadeIn" data-wow-delay="0.25s">
                                            ACCESANDO AL SISTEMA
                                        </h4>
                                        <div className="white-space-4"></div>
                                        <p className="text-center wow fadeIn" data-wow-delay="0.30s">
                                            Espere un par de segundos
                                        </p>
                                        <div className="white-space-16"></div>
                                        <div className="justify-center align-center wow fadeIn" data-wow-delay="0.35s">
                                            <i className="fas fa-spinner fa-spin fa-2x color-green"></i>
                                        </div>
                                    </div>
                                :
                                    <div className="column">
                                        <div className="responsive-img justify-center align-center wow fadeIn">
                                            <img src = { Logo } alt="Altura logo" title="Altura logo"/>
                                        </div>
                                        <div className="white-space-16"></div>
                                        <h3 className="text-center color-darkgray">
                                            GENERAR CONTRASEÑA
                                        </h3>
                                        <div className="white-space-24"></div>
                                        <div className="input-group row justify-center">
                                            <div className="icon align-center">
                                                <i className="fas fa-key"></i>                            
                                            </div>
                                            <input type="password" className="input" name="password" placeholder="Nueva contraseña" minLength = "8" maxLength = "16" />
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="input-group row justify-center">
                                            <div className="icon align-center">
                                                <i className="fas fa-key"></i>                            
                                            </div>
                                            <input type="password" className="input" name="repassword" placeholder="Repetir contraseña" minLength = "8" maxLength = "16" />
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="btn-container justify-center">
                                            <button type="submit" className="btn btn-radius btn-primary">
                                                {
                                                    this.state.loadingChange ? 
                                                    <i className="fas fa-spinner fa-spin color-white"></i> :
                                                    "ACTUALIZAR"
                                                }
                                                
                                            </button>
                                        </div>
                                        <div className="white-space-8"></div>
                                        {
                                            this.state.message ? 
                                            <p className="text-center weight-medium">
                                                { this.state.message }
                                            </p> :
                                            <p className="color-white text-center">!Buh!</p>
                                        }
                                        <div className="white-space-16"></div>
                                        <div className="justify-center">
                                            <Link to="/" className="text-center font-mini">
                                                Cancelar
                                            </Link>
                                        </div>
                                    <div className="white-space-8"></div>
                                    </div>
                            }
                            </form>
                        </div>
                    </div>
                </div>
                { this.state.showPopover && <Popover type = { this.state.type } message = { this.state.message } /> }
            </div>
        );
    }

    componentDidMount() {
        new WOW.WOW({ live: false }).init();
        window.localStorage.setItem('AlturaCRM_Change', true);
        this.loadUser();
    }

    /**
     * @function: loadUser
     * @description: Función para validar si es la primera vez del usuario
    */
    async loadUser() {
        const { user } = await this.props.context.loadUser();
        if (user) { 
            this.setState({ idUsuario: user.idUsuario });
            if (user.nuevo) {
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 3000);
            } else { 
                window.localStorage.removeItem('AlturaCRM_Change');
                setTimeout(() => {
                    this.props.history.push('/clientes'); 
                }, 3000);
            }
        }
    }
    
    /**
     * @function: handleSubmit
     * @param: event: object
     * @description: Función para actualizar la contraseña de usuario
    */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loadingChange: true });
        const form = event.target;
        if (form.password.value === form.repassword.value) {
            const data = {
                idUsuario: this.state.idUsuario,
                password: form.password.value,
            };
            const response = await request.put('/users/login/primera', data);
            if (response && !response.error) {
                if (response.updated) {
                    this.setState({ showPopover: true, message: 'Contraseña actualizado exitosamente, serás redireccionado', type: 'success', loadingChange: false });
                    window.localStorage.removeItem('AlturaCRM_Change');
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 3500);
                } else {
                    this.setState({ showPopover: true, type: 'danger', message: 'No fue posible actualizar la contraseña de usuario', loadingChange: false });
                }
            } else {
                this.setState({ loadingChange: false, error: true });
            }
        } else {
            alert('Las contraseñas no coinciden');
            this.setState({ loadingChange: false });
        }
    }
}

 export default withRouter(Consumer(LoginValidate));