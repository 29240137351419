/**
 *  login.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página de login
 */

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Consumer } from '../context';
import { Helmet } from 'react-helmet';
import Popover from '../components/popover';
import WOW from 'wowjs';

// Imágenes
import Logo from '../img/logoblanco.jpg';

// HTTP Client
import Request from '../config/http';
const request = new Request();

 class Login extends Component {

    state = {
        loading: false, message: '', type: 'success', showPopover: false,
        auth: window.localStorage.getItem('AlturaCRM'), change: window.localStorage.getItem('AlturaCRM_Change'), error: false
    }

    render() {
        return(
            <div className="login align-center">
                <Helmet>
                    <title>Login - Altura CRM</title>
                </Helmet>
                <div className="justify-center align-center">
                    <div className="container">
                        <div className="justify-center align-center">
                            <form className="login-area column" onSubmit = { this.handleSubmit.bind(this) }>
                                <div className="responsive-img justify-center align-center wow fadeIn">
                                    <img src = { Logo } alt="Altura logo" title="Altura logo"/>            
                                </div>
                                <div className="white-space-16"></div>
                                <div className="input-group row justify-center wow fadeIn" data-wow-delay="0.25s">
                                    <div className="icon align-center">
                                        <i className="fas fa-at"></i>                            
                                    </div>
                                    <input type="email" className="input" name="email" minLength = "6" maxLength = "64" 
                                    placeholder="Correo electrónico" />
                                </div>
                                <div className="white-space-16"></div>
                                <div className="input-group row justify-center wow fadeIn" data-wow-delay="0.35s">
                                    <div className="icon align-center">
                                        <i className="fas fa-key"></i>                            
                                    </div>
                                    <input type="password" minLength = "4" maxLength = "32" className="input" 
                                    name="password" placeholder="Contraseña" />
                                </div>
                                <div className="white-space-16"></div>
                                <div className="btn-container justify-center wow fadeIn" data-wow-delay="0.45s">
                                    <button type="submit" className="btn btn-radius btn-primary">
                                        {
                                            this.state.loading ? 
                                                <i className="fas fa-spinner fa-spin color-white"></i> : "INGRESAR"
                                        }
                                    </button>
                                </div>
                                <div className="white-space-24"></div>
                                <div className="justify-center wow fadeIn" data-wow-delay="0.55s">
                                    <Link to="/restaurar" className="text-center font-mini">
                                        ¿Olvidaste tu contraseña?
                                    </Link>            
                                </div>
                                <div className="white-space-8"></div>
                            </form>
                        </div>
                    </div>
                    { this.state.showPopover && <Popover type = { this.state.type } message = { this.state.message } /> }
                </div>
            </div>
        );
    }

    componentDidMount() {
        new WOW.WOW({ live: false }).init();
        if (this.state.auth && !this.state.change) {
            this.props.history.push('/clientes');
        } else {
            this.props.history.push('/');
        }
    }

    /**
     * @function: handleSubmit
     * @param: event: object
     * @description: Función para realizar Login de la aplicación
    */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;
        const data = {
            email: form.email.value,
            password: form.password.value
        }
        const response = await request.post('/users/login', data);
        if (response && !response.error) {
            if (response.user && !response.empty) {
                this.setState({ loading: false });
                this.props.context.login({ user: response.user, name: response.name, auth: true });
                this.props.history.push('/validar');
            }
        } else {
            this.setState({ loading: false, error: true, message: response.message, showPopover: true, type: 'danger' });
            setTimeout(() => {
                this.setState({ showPopover: false });
            }, 4000);
        }
    }

}

 export default withRouter(Consumer(Login));