/**
 *  users-add.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Componente de popover
*/

import React from 'react';

const Popover = (props) => (
    <div className = { `popover popover-${ props.type } justify-center wow animated fadeInUp }` }>
        <div className="container row">
            <p className="color-white text-center weight-medium full">
                { props.message }
            </p>
        </div>
    </div>
);

export default Popover;