/**
 *  users.js
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Manejador de ejecuciones del Context para Usuarios.
*/

// HTTP Cliente
import Request from '../config/http';
const request = new Request();

/**
 * @function: login
 * @param: data: Object
 * @description: Función para asignar Token de usuario al estado global
*/
export function login(data) {
    window.localStorage.setItem("AlturaCRM", JSON.stringify(data));
    window.localStorage.setItem("AlturaCRM_Nombre", data.name);
    this.setState({ user: data });
};


/**
 * @function: logout
 * @param: data: Object
 * @description: Función para remover Token de usuario del estado global y del local storage
*/
export function logout() {
    window.localStorage.removeItem("AlturaCRM");
    window.localStorage.removeItem('AlturaCRM_Nombre');
};


/**
 * @function: loadUser
 * @description: Función para resolver el Token de usuario y asignarlo al estado global.
*/
export async function loadUser() {
    let user = window.localStorage.getItem("AlturaCRM");
    if (user) {        
        user = JSON.parse(user);
        const response = await request.post('/users/token', { user: user.user });
        if (response.data) {
            user.user = JSON.parse(response.data);
        } else {
            user.user = { idUsuario: 0, correo: '', nombre: '' }
        }
        this.setState({ user: user });
        return user;
    }
};