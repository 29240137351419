/**
 *  clients-archive.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página de clientes archivados
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import TableArchive from '../components/tables/table-archive';
import Paginator from '../components/paginator';

// HTTP Client
import Request from '../config/http';
const request = new Request();

class ClientsArchive extends Component {

    state = {
        user: { idUsuario: 0, nombre: '', correo: '', admin: 0 }, clients: [], page: 1, loadingClients: false
    };

    render() {
        let pages;
        if (this.state.clients) {
            pages = Math.ceil(this.state.clients.length / 8);
        }
        return(
            <div className="dashboard">
                <Helmet>
                    <title>Archivados - Altura CRM</title>
                </Helmet>
                <Header breadcrumb = "Tablero > Clientes > Archivados" type = { this.state.user.admin } />
                <div className="white-space-32"></div>
                <TableArchive data = { this.state.clients } page = { this.state.page } loading = { this.state.loadingClients } admin = { this.state.user } />
                <Paginator pages = { pages } setPage = { this.setPage.bind(this) } scroll = { false } />
            </div>
        );
    }

    componentDidMount() {
        this.loadUser(false);
    }

    /**
     * @function: loadUser
     * @description: Función para recuperar la información del usuario activo
    */
    async loadUser() {
        this.setState({ loadingClients: true });
        const user = await this.props.context.loadUser();
        if (user) { 
            this.setState({ user: user.user });
            this.getClients();
        }
    }

        /**
     * @function: getClients
     * @description: Función para recuperar la información de todos los clientes
    */
    async getClients() {
        const response = await request.post('/clients/archive','');
        if (response && !response.error) {
            this.setState({ clients: response.clients, loadingClients: false });
        } else {
            this.setState({ loadingClients: false, error: true });
        }
    }

    /**
     * @function: setPage
     * @param: page: int
     * @description: Función actualizar el valor de página del paginador
    */
    setPage(page) { this.setState({ page }); }

}

export default Consumer(ClientsArchive);