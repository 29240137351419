/**
 *  clients-add.jsx
 *  @version: 1.0.0
 *  @author: Absolem - Sergio
 *  @description: Página para añadir usuario
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Popover from '../components/popover';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import WOW from 'wowjs';

// HTTP Client
import Request from '../config/http';
const request = new Request();

class ClientsAdd extends Component {

    state = { user: { idUsuario: 0, nombre: '', correo: '' }, admin: false, loading: false, 
    birthday: new Date(), showPopover: false, type: 'success', message: '' }

    render() {
        return(
            <div className="dashboard">
                <Helmet>
                    <title>Añadir clientes - Altura CRM</title>
                </Helmet>
                <Header breadcrumb = "Tablero > Clientes > Nuevo" type = { this.state.user.admin } />
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-32"></div>
                        <form className="form-users column wow animated fadeIn" onSubmit = { this.handleSubmit.bind(this) }>
                            <div className="title">
                                <h4 className="color-darkgray">
                                    AÑADIR CLIENTE
                                </h4>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="row-responsive full">
                                <div className="column full mr">
                                    <p className="weight-medium">
                                        Correo electrónico *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-at"></i>
                                        <input type="email" name="email" required minLength = "8" maxLength = "64" className="input full" />
                                    </div>
                                </div>
                                <div className="row-responsive full">
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Nombre(s) *
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-font"></i>
                                            <input type="text" name="name" required minLength = "3" maxLength = "48" className="input full" />
                                        </div>
                                    </div>
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Apellido paterno *
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-font"></i>
                                            <input type="text" name="middlename" required minLength = "3" maxLength = "48" className="input full" />
                                        </div>
                                    </div>
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Apellido materno *
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-font"></i>
                                            <input type="text" name="lastname" required minLength = "3" maxLength = "48" className="input full" />
                                        </div>
                                    </div>              
                                </div>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="row-responsive full">
                                <div className="row-responsive full mr">
                                    <div className="column full">
                                        <p className="weight-medium">
                                            Teléfono *
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-phone"></i>
                                            <input type="text" name="phone" required minLength = "8" maxLength = "24" className="input full" />
                                        </div>
                                    </div>
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Teléfono secundario
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-phone"></i>
                                            <input type="text" name="phone2" minLength = "8" maxLength = "24" className="input full" />
                                        </div>
                                    </div>
                                </div>
                                <div className="column full ml">
                                    <p className="weight-medium">
                                        Fecha de nacimiento *
                                    </p>
                                    <div className="white-space-8"></div>
                                    <div className="input-container">
                                        <i className="fas fa-calendar-alt"></i>
                                        <DatePicker className="input full" peekNextMonth showMonthDropdown showYearDropdown dateFormat = "yyyy-MM-dd"
                                        selected = { this.state.birthday } onChange = { (date) => this.setState({ birthday: date }) } required />
                                    </div>
                                </div>
                            </div>

                            <div className="white-space-24"></div>
                            <div className="row-responsive full">
                                <div className="row-responsive full">
                                    <div className="column full mr">
                                        <p className="weight-medium">
                                            Situación familiar
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-user-friends"></i>
                                            <select name="situation" className="input input-select full">
                                                <option value="Soltero">Soltero</option>
                                                <option value="Casado">Casado</option>
                                                <option value="Divorciado">Divorciado</option>
                                                <option value="Unión libre">Unión Libre</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column full mr">
                                        <p className="weight-medium">
                                            Número de hijos
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-users"></i>
                                            <select name="children" className="input input-select full">
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-responsive full">
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Motivo de cambio
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-person-booth"></i>
                                            <select name="change" className="input input-select full">
                                                <option value="Alquiler">Alquiler</option>
                                                <option value="Traslado / Profesionales">Traslado / Profesionales</option>
                                                <option value="Mejora de vivienda">Mejora de vivienda</option>
                                                <option value="Inversión">Inversión</option>
                                                <option value="Segunda residencia">Segunda residencia</option>
                                                <option value="Casarse / Independizarse">Casarse / Independizarse</option>
                                                <option value="Montar negocio">Montar negocio</option>
                                                <option value="Para hijos">Para hijos</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Ocupación
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-user-tie"></i>
                                            <input type="text" name="job" minLength = "8" maxLength = "48" className="input full" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="white-space-24"></div>
                            <div className="row-responsive full">
                                <div className="row-responsive full">
                                    <div className="column full mr">
                                        <p className="weight-medium">
                                            Forma de pago
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-wallet"></i>
                                            <select name="payment" className="input input-select full">
                                                <option value="Crédito">Crédito</option>
                                                <option value="Contado">Contado</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column full mr">
                                        <p className="weight-medium">
                                            Rango de inversión
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-exchange-alt"></i>
                                            <select name="range" className="input input-select full">
                                                <option value="2800"> Menor a $2.8 MDP</option>
                                                <option value="2800:3200"> Entre a $2.8 y $3.2 MDP</option>
                                                <option value="3200:4300"> Entre a $3.2 y $4.3 MDP</option>
                                                <option value="4300"> Mayor a $4.3 MDP</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row-responsive full">
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Número de habitaciones
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-person-booth"></i>
                                            <select name="rooms" className="input input-select full">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column full ml">
                                        <p className="weight-medium">
                                            Procedencia del contacto
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container">
                                            <i className="fas fa-mail-bulk"></i>
                                            <select name="media" className="input input-select full">
                                                <option value="Página Web">Página Web</option>
                                                <option value="Facebook">Facebook</option>
                                                <option value="Instagram">Instagram</option>
                                                <option value="Twitter">Twitter</option>
                                                <option value="Recomendaciones">Referencia / Recomendaciones</option>
                                                <option value="Caseta de ventas">Caseta de ventas</option>
                                                <option value="Google">Google</option>
                                                <option value="otro">Otro</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="white-space-24"></div>
                            <div className="row-responsive full">
                                <div className="input-container full column">
                                    <i className="fas fa-mail-bulk"></i>
                                    <textarea className="input full" maxLength="300" rows="6" name="comments" placeholder="Comentarios (Opcional)"></textarea>
                                </div>
                            </div>

                            <div className="white-space-24"></div>
                            <div className="btn-container row">
                                <button type="submit" className="btn btn-primary btn-radius color-white">
                                    {
                                        this.state.loading ?
                                        <span className="font-mini color-white">
                                            <i className="fas fa-spinner fa-spin"></i> &nbsp; GUARDANDO CLIENTE
                                        </span>
                                        :
                                        <span className="font-mini color-white">
                                            <i className="fas fa-check"></i> &nbsp; GUARDAR CLIENTE
                                        </span>
                                    }
                                </button>
                                <button type = "button" className="btn btn-danger btn-radius color-white" onClick = { this.handleExit.bind(this) }>
                                    <i className="fas fa-times"></i>
                                    &nbsp; CANCELAR
                                </button>
                            </div>
                        </form>
                        <div className="white-space-32"></div>
                        { this.state.showPopover && <Popover type = { this.state.type } message = { this.state.message } /> }
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(){
        new WOW.WOW({ live: false }).init();
        this.loadUser();
    }

    /**
     * @function: loadUser
     * @description: Función para recuperar la información del usuario activo
    */
    async loadUser() {
        this.setState({ loadingUsers: true });
        const user = await this.props.context.loadUser();
        if (user){ 
            this.setState({ user: user.user });
        }
    }

    /**
     * @function: handleSubmit
     * @params: event: object, data: object (email: string, fullName: string, phone: string, rfc: string, password: string, admin: int)
     * @description: Función para insertar clientes
    */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;
        const phone = form.phone.value;
        if (isNaN(parseInt(phone))) {
            this.setState({ loading: false });
            alert("El formato del teléfono no es correcto");
        } else {
            const data = {
                name: form.name.value, middlename: form.middlename.value, lastname: form.lastname.value, email: form.email.value, phone: phone, 
                phone2: form.phone2.value, media: form.media.value, idUsuario: this.state.user.idUsuario, situation: form.situation.value, 
                birthday: this.state.birthday, children: form.children.value, change: form.change.value, payment: form.payment.value, range: form.range.value, rooms: form.rooms.value, job: form.job.value, comments: form.comments.value
            };
            const response = await request.post('/clients/add', data);
            if (response && !response.error) {
                if (response.status.status === 1) {
                    this.setState({ loading: false, showPopover: true, message: 'CLIENTE REGISTRADO EXITOSAMENTE', type: 'success' });
                    setTimeout(() => {
                        this.setState({ showPopover: false });
                        this.props.history.push('/clientes');
                    }, 4000);
                } else {
                    this.setState({ loading: false, showPopover: true, message: 'EL CLIENTE SE ENCUENTRA REGISTRADO', type: 'danger' });
                    setTimeout(() => {
                        this.setState({ showPopover: false });
                    }, 4000);
                }
            } else {
                this.setState({ loading: false, error: true, showPopover: true, message: 'NO FUE POSIBLE CONECTARSE A LOS SERVICIOS', type: 'danger' });
                setTimeout(() => {
                    this.setState({ showPopover: false });
                }, 4000);
            }
        }
    }

    handleExit() { this.props.history.push('/clientes'); }

}

export default withRouter(Consumer(ClientsAdd));